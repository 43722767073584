import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 1013.000000 1024.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)" >





<path d="M10060 5155 l0 -5085 -5030 0 -5030 0 0 -30 0 -30 5058 2 c4809 3
5057 4 5056 21 -1 9 -2 2310 -3 5112 l-1 5095 -25 0 -25 0 0 -5085z"/>
<path d="M6930 8338 c-230 -14 -444 -103 -642 -267 -47 -39 -68 -63 -68 -79 0
-41 27 -35 92 20 220 186 442 269 722 268 158 0 294 -27 432 -87 101 -44 224
-124 296 -191 48 -45 72 -49 84 -17 8 20 -22 53 -111 119 -245 184 -485 253
-805 234z"/>
<path d="M6895 8029 c-68 -10 -189 -51 -255 -87 -114 -63 -217 -150 -207 -175
13 -34 36 -29 91 19 119 105 286 176 446 190 196 16 416 -57 567 -189 57 -50
69 -53 87 -23 24 39 -178 180 -329 231 -128 43 -260 54 -400 34z"/>
<path d="M6914 7715 c-74 -16 -165 -61 -221 -110 -46 -39 -52 -55 -28 -75 13
-11 20 -10 43 8 105 84 175 116 280 128 110 12 249 -33 341 -110 46 -39 58
-41 75 -12 9 15 4 24 -31 55 -120 108 -295 152 -459 116z"/>
<path d="M6961 7338 c-56 -28 -81 -69 -81 -134 0 -93 71 -159 161 -151 151 13
193 214 59 284 -54 29 -83 29 -139 1z m104 -52 c59 -25 74 -93 31 -143 -19
-22 -32 -28 -66 -28 -34 0 -47 6 -66 28 -40 47 -29 113 24 141 34 19 35 19 77
2z"/>
<path d="M2283 7233 c-12 -30 6 -40 83 -46 115 -9 158 -48 174 -158 5 -35 10
-281 10 -546 l0 -482 -42 -38 c-116 -104 -157 -145 -226 -228 -443 -527 -533
-1259 -232 -1880 236 -487 693 -835 1245 -948 94 -19 142 -22 330 -22 190 0
236 3 333 23 262 53 521 167 722 318 63 47 122 90 131 95 10 5 213 9 474 9
l456 0 -3 23 c-3 20 -10 23 -80 28 -89 8 -133 29 -163 79 -18 32 -20 55 -23
273 l-3 237 68 88 c115 146 195 258 342 477 93 137 150 212 164 217 12 3 62 6
111 7 190 2 407 50 555 122 64 32 102 60 161 118 127 125 173 248 173 456 0
176 -36 293 -125 405 l-42 52 25 19 c40 32 200 107 264 124 98 26 266 31 365
11 185 -38 316 -111 448 -251 63 -67 90 -105 131 -187 67 -136 85 -212 85
-363 0 -150 -18 -228 -84 -360 -132 -265 -386 -431 -690 -452 -74 -5 -155 3
-252 23 -24 5 -28 3 -28 -14 0 -29 48 -41 185 -49 181 -9 347 29 486 112 468
280 572 880 225 1293 -76 91 -155 154 -260 206 -107 54 -129 63 -206 80 -238
54 -475 9 -678 -128 l-54 -36 -41 29 c-62 42 -100 60 -206 96 -206 71 -388 85
-1083 85 l-517 0 -6 38 c-97 575 -420 900 -1015 1020 -123 25 -134 25 -665 29
-297 1 -647 4 -779 6 -183 1 -240 -1 -243 -10z m1496 -77 c299 -84 520 -266
658 -541 40 -78 97 -257 117 -364 59 -309 35 -735 -55 -1019 -34 -105 -101
-247 -152 -321 -58 -85 -183 -204 -271 -259 -207 -130 -421 -179 -745 -169
-259 7 -327 36 -350 150 -8 35 -10 295 -9 807 l3 755 95 33 c101 35 291 76
401 87 l67 6 -25 -48 c-37 -73 -18 -142 50 -183 79 -48 185 2 203 95 9 48 -25
119 -68 141 -18 9 -36 22 -40 27 -9 15 -166 10 -290 -8 -115 -17 -236 -47
-325 -80 -36 -14 -67 -25 -69 -25 -9 0 -3 759 7 800 18 77 75 119 194 140 28
5 154 7 280 6 213 -3 237 -6 324 -30z m2373 -1089 c174 -47 287 -120 362 -235
25 -37 52 -90 62 -117 l17 -50 -92 -125 c-85 -115 -334 -484 -441 -656 l-45
-71 -140 -7 c-77 -4 -200 -6 -272 -5 l-133 2 0 650 0 649 293 -4 c276 -5 297
-6 389 -31z m-1124 -74 c16 -35 17 -133 20 -1221 l3 -1184 -43 -40 c-77 -71
-200 -168 -214 -168 -8 0 -14 -6 -14 -12 -1 -15 -108 -95 -255 -191 -400 -261
-960 -328 -1433 -171 -454 151 -818 471 -1016 896 -117 249 -163 483 -153 781
12 357 109 637 325 942 63 87 278 308 293 300 5 -4 8 -280 6 -658 -2 -602 -3
-655 -20 -693 -25 -57 -79 -86 -174 -92 -69 -4 -73 -6 -73 -28 l0 -24 659 0
c392 0 701 4 762 11 400 41 718 175 934 393 243 246 365 580 368 1013 1 101 3
183 5 183 2 0 11 -17 20 -37z m1591 -583 c1 -157 -15 -246 -59 -336 -50 -102
-133 -172 -253 -214 -57 -20 -227 -53 -234 -46 -9 9 320 506 453 686 77 103
78 105 85 75 4 -16 7 -91 8 -165z m-706 -747 c-144 -219 -426 -613 -439 -613
-2 0 -4 158 -4 350 l0 350 250 0 250 0 -57 -87z m-884 -1180 c-19 -50 -51 -77
-113 -94 -27 -8 -23 -1 39 56 39 35 73 64 77 65 4 0 3 -12 -3 -27z"/>
<path d="M3464 4235 c-4 -11 -32 -78 -61 -149 -29 -71 -53 -130 -53 -132 0 -2
7 -4 15 -4 8 0 21 16 29 35 15 35 15 35 81 35 l65 0 16 -40 c9 -22 22 -40 30
-40 8 0 14 2 14 4 0 6 -110 278 -119 295 -9 14 -10 14 -17 -4z m51 -157 c7
-28 6 -28 -39 -28 -51 0 -55 6 -32 61 7 19 17 43 21 54 7 18 10 15 25 -20 10
-22 22 -52 25 -67z"/>
<path d="M4550 4231 c-20 -38 -110 -265 -110 -278 0 -24 28 -1 42 35 l14 37
67 -3 c65 -4 66 -4 77 -37 11 -32 40 -56 40 -33 0 10 -108 287 -116 296 -1 2
-7 -6 -14 -17z m60 -173 c0 -5 -23 -8 -51 -8 -57 0 -57 -1 -17 90 l19 45 24
-60 c14 -33 25 -63 25 -67z"/>
<path d="M2787 4233 c-4 -3 -7 -68 -7 -144 0 -156 -1 -154 81 -143 85 11 136
57 146 132 7 55 -26 116 -77 142 -38 19 -129 28 -143 13z m139 -43 c63 -40 70
-136 13 -184 -24 -20 -42 -26 -80 -26 l-49 0 0 108 c0 60 3 112 7 115 14 14
77 6 109 -13z"/>
<path d="M3930 4225 c0 -10 11 -15 35 -15 l35 0 0 -130 c0 -109 2 -130 15
-130 13 0 15 21 15 130 l0 130 35 0 c24 0 35 5 35 15 0 12 -16 15 -85 15 -69
0 -85 -3 -85 -15z"/>
<path d="M7402 4103 c-19 -91 -28 -151 -22 -157 12 -12 25 21 45 118 17 79 24
84 43 31 17 -48 59 -145 65 -151 3 -3 22 40 44 95 21 55 41 101 44 101 3 0 13
-40 23 -90 10 -49 25 -95 32 -101 11 -9 14 -7 14 7 0 32 -54 284 -61 284 -4 0
-25 -48 -46 -107 -22 -60 -44 -106 -49 -104 -5 3 -27 51 -50 108 -23 57 -43
105 -46 108 -2 2 -18 -62 -36 -142z"/>
<path d="M5902 4095 c2 -88 7 -145 13 -145 6 0 11 25 13 55 l3 55 39 0 c53 0
85 16 106 55 15 28 16 37 4 64 -17 42 -56 61 -125 61 l-56 0 3 -145z m136 102
c6 -6 13 -24 17 -39 10 -40 -23 -68 -80 -68 l-45 0 0 60 0 60 49 0 c27 0 53
-6 59 -13z"/>
<path d="M6442 4093 c2 -106 6 -147 16 -151 9 -3 12 13 12 57 0 53 3 61 18 61
11 0 38 -25 64 -58 25 -32 53 -59 61 -59 18 0 15 7 -23 57 -43 56 -43 58 -11
74 37 20 58 74 40 108 -22 44 -50 58 -117 58 l-63 0 3 -147z m142 95 c36 -51
3 -98 -69 -98 l-45 0 0 60 0 60 49 0 c40 0 53 -4 65 -22z"/>
<path d="M6990 4089 c0 -116 3 -150 13 -147 9 4 13 45 15 151 2 126 0 147 -13
147 -13 0 -15 -22 -15 -151z"/>
<path d="M8052 4093 l3 -148 79 0 c55 0 81 4 84 13 3 9 -15 12 -67 12 l-71 0
0 55 0 55 71 0 c51 0 70 3 67 12 -3 8 -28 12 -69 13 l-64 0 -3 53 -3 52 70 0
c56 0 71 3 71 15 0 12 -16 15 -85 15 l-86 0 3 -147z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
